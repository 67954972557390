<script>
export default {
    props: {
        data: {
            type: String,
        },
    },
    data() {
        return {
            process: [],
            logo1: String,
            logo2: String,
            logo3: String,
            logo4: String,
            logo5: String,
            logo6: String,
            logo7: String,
            logo8: String,
            logo9: String,
            logo10: String,
        };
    },
    beforeMount() {
        this.process = JSON.parse(this.data);
        this.logo1 = this.process.logo.logo_1.image.path;
        this.logo2 = this.process.logo.logo_2.image.path;
        this.logo3 = this.process.logo.logo_3.image.path;
        this.logo4 = this.process.logo.logo_4.image.path;
        this.logo5 = this.process.logo.logo_5.image.path;
        this.logo6 = this.process.logo.logo_6.image.path;
        this.logo7 = this.process.logo.logo_7.image.path;
        this.logo8 = this.process.logo.logo_8.image.path;
        this.logo9 = this.process.logo.logo_9.image.path;
        this.logo10 = this.process.logo.logo_10.image.path;
    }
};
</script>

<template>
    <section class="process-section container">
        <h2 class="section-title-menu">{{ process.title }}</h2>

        <div class="provider-process">
            <div class="provider-title">
                <span class="provider-title-text">{{ process.provider_title }}</span>
                <img :src="logo1" alt="Provider process icon" class="provider-title-icon" loading="lazy"/>
            </div>

            <div class="provider-step">
                <img :src="logo2" alt="Register information icon" class="provider-step-icon" loading="lazy"/>
                <p class="provider-step-text">{{ process.provider_step_1 }}</p>
            </div>

            <div class="provider-step">
                <img :src="logo3" alt="Confirm information icon" class="provider-step-icon" loading="lazy"/>
                <p class="provider-step-text">{{ process.provider_step_2 }}</p>
            </div>

            <div class="provider-step">
                <img :src="logo4" alt="Exchange work process icon" class="provider-step-icon" loading="lazy"/>
                <p class="provider-step-text">{{ process.provider_step_3 }}</p>
            </div>

            <div class="provider-step">
                <img :src="logo5" alt="Sell products icon" class="provider-step-icon" loading="lazy"/>
                <p class="provider-step-text">{{ process.provider_step_4 }}</p>
            </div>
        </div>

        <div class="provider-process">
            <div class="provider-title" style="background: #eec144">
                <span class="provider-title-text">{{ process.customer_title }}</span>
                <img :src="logo6" alt="Provider process icon" class="provider-title-icon" loading="lazy"/>
            </div>

            <div class="provider-step">
                <img :src="logo7" alt="Register information icon" class="provider-step-icon" loading="lazy"/>
                <p class="provider-step-text">{{ process.customer_step_1 }}</p>
            </div>

            <div class="provider-step">
                <img :src="logo8" alt="Confirm information icon" class="provider-step-icon" loading="lazy"/>
                <p class="provider-step-text">{{ process.customer_step_2 }}</p>
            </div>

            <div class="provider-step">
                <img :src="logo9" alt="Exchange work process icon" class="provider-step-icon" loading="lazy"/>
                <p class="provider-step-text">{{ process.customer_step_3 }}</p>
            </div>

            <div class="provider-step">
                <img :src="logo10" alt="Sell products icon" class="provider-step-icon" loading="lazy"/>
                <p class="provider-step-text">{{ process.customer_step_4 }}</p>
            </div>
        </div>
    </section>
</template>
