<template>
    <section class="landscape-products-wrap" v-if="hasAnyProduct">
        <div class="container">
            <div class="products-header">
                <h1 class="section-title">
                    {{ $trans("storefront::product.related_products") }}
                </h1>
            </div>
            <div class="landscape-products" ref="productsPlaceholder">
                <RelatedProductCard
                    v-for="(product, index) in products"
                    :key="index"
                    :product="product"
                ></RelatedProductCard>
            </div>
        </div>
    </section>
</template>

<script>
import { slickPrevArrow, slickNextArrow } from "../../../functions";
import RelatedProductCard from "./../../RelatedProductCard.vue";

export default {
    components: { RelatedProductCard },

    props: ["products"],

    computed: {
        hasAnyProduct() {
            return this.products.length !== 0;
        },
    },

    mounted() {
        $(this.$refs.productsPlaceholder).slick({
            rows: 0,
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            rtl: window.FleetCart.rtl,
            prevArrow: slickPrevArrow(),
            nextArrow: slickNextArrow(),
            responsive: [
                {
                    breakpoint: 1761,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    },
                },
                {
                    breakpoint: 1341,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    },
                },
                {
                    breakpoint: 881,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 661,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 641,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    },
};
</script>
