<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
    components: {
        VueSlickCarousel,
    },
    props: {
        data: String,
        btnPrev: {
            type: String,
            default: "build/assets/btn-prev.svg"
        },
        btnNext: {
            type: String,
            default: "build/assets/btn-next.svg"
        }
    },
    data() {
        return {
            slickOptions: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 1
                        },
                    },
                ],
            },
            customers: [],
            images: [],
        };
    },
    methods: {
        prevSlide() {
            this.$refs.slick.prev();
        },
        nextSlide() {
            this.$refs.slick.next();
        },
    },
    beforeMount() {
        this.customers = JSON.parse(this.data);
        this.images = this.customers.customers;
    }
};
</script>
<template>
    <section class="customer-section">
        <div class="container">
            <h2 class="section-title-menu">{{ customers.title }}</h2>
            <div class="customer-wrapper">
                <div class="navigation-button" @click="prevSlide" v-if="this.images.length > 10">
                    <img loading="lazy" :src="btnPrev" class="nav-icon" alt="btn_prev" />
                </div>
                <div class="customer-logos">
                    <VueSlickCarousel ref="slick" v-bind="slickOptions">
                        <div class="logo-row">
                            <img
                                loading="lazy"
                                :src="item"
                                class="logo"
                                alt="Customer Logo 1"
                                v-for="item in images" :key="item"
                            />
                        </div>
                    </VueSlickCarousel>
                </div>
                <div class="navigation-button" @click="nextSlide" v-if="this.images.length > 10">
                    <img loading="lazy" :src="btnNext" class="nav-icon" alt="btn_next" />
                </div>
            </div>
        </div>
    </section>
</template>
