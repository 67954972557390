<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: "VisionMissionValues",
    components: {
        VueSlickCarousel,
    },
    props: {
        defaultImage: {
            type: String,
            default: "build/assets/showcase_default.png"
        },
        data: String
    },
    data() {
        return {
            sliderSettings: {
                arrows: false,
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplaySpeed: 3000,
                dotsClass: "slick-dots custom-dots",
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                        },
                    },
                ],
            },
            sliders: [],
            logo1: String,
            logo2: String,
            logo3: String,
            logo4: String,
        };
    },
    beforeMount() {
        this.vision = JSON.parse(this.data)
        this.logo1 = this.vision.logo.logo_1.image.path;
        this.logo2 = this.vision.logo.logo_2.image.path;
        this.logo3 = this.vision.logo.logo_3.image.path;
        this.logo4 = this.vision.logo.logo_4.image.path;
    },
};
</script>

<template>
    <div class="vision-mission-values container">
        <div class="vision-mission-values__content">
            <div class="vision-mission-values__text">
                <div class="vision-mission-values__heading">
                    <h2 class="vision-mission-values__title">
                        {{ vision.title }}
                    </h2>
                    <div class="vision-mission-values__body">
                        <strong>{{ vision.title_vision }}</strong>
                        <span>{{ vision.content_vision }}</span>
                    </div>
                    <div class="vision-mission-values__body">
                        <strong>{{ vision.title_mission }}</strong>
                        <span>{{ vision.content_mission }}</span>
                    </div>
                    <div class="vision-mission-values__core-values">
                        <div class="vision-mission-values__core-values-row">
                            <div class="vision-mission-values__core-value">
                                <img :src="logo1" alt="quality" class="vision-mission-values__core-value-icon" />
                                <h3 class="vision-mission-values__core-value-title">
                                    {{ vision.title_quality }}
                                </h3>
                                <p class="vision-mission-values__core-value-description">
                                    {{ vision.content_quality }}
                                </p>
                            </div>
                            <div class="vision-mission-values__core-value">
                                <img :src="logo2" alt="learning" class="vision-mission-values__core-value-icon" />
                                <h3 class="vision-mission-values__core-value-title">
                                    {{ vision.title_learning }}
                                </h3>
                                <p class="vision-mission-values__core-value-description">
                                    {{ vision.content_learning }}
                                </p>
                            </div>
                        </div>
                        <div class="vision-mission-values__core-values-row">
                            <div class="vision-mission-values__core-value">
                                <img :src="logo3" alt="hearing" class="vision-mission-values__core-value-icon" />
                                <h3 class="vision-mission-values__core-value-title">
                                    {{ vision.title_hearing }}
                                </h3>
                                <p class="vision-mission-values__core-value-description">
                                    {{ vision.content_hearing }}
                                </p>
                            </div>
                            <div class="vision-mission-values__core-value">
                                <img :src="logo4" alt="creative" class="vision-mission-values__core-value-icon" />
                                <h3 class="vision-mission-values__core-value-title">
                                    {{ vision.title_creative }}
                                </h3>
                                <p class="vision-mission-values__core-value-description">
                                    {{ vision.content_creative }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vision-mission-values__image">
                <VueSlickCarousel v-bind="sliderSettings">
                    <img v-for="item in vision.sliderVision" :key="item.id" :src="item.image.path ?? defaultImage"
                        :alt="item.name" class="vision-mission-values__img" />
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>
