<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: 'BestSellerProducts',
    components: {
        VueSlickCarousel
    },
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/category_default.png"
        }
    },
    data() {
        return {
            sliderSettings: {
                arrows: false,
                dots: true,
                slidesToShow: 4,
                speed: 500,
                slidesToScroll: 1,
                autoplaySpeed: 3000,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots: false,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                        },
                    },
                ],
            },
            categories: []
        };
    },
    beforeMount() {
        this.categories = JSON.parse(this.data);
    }
};
</script>

<template>
    <section class="product-categories container">
        <h2 class="section-title-menu">{{ categories.title }}</h2>
        <section class="category-grid-menu">
            <VueSlickCarousel v-bind="sliderSettings">
                <a :href="'/categories/' + category.slug + '/products'" class="category-item" v-for="category in categories.bestSellerCategories" :key="category.id">
                    <img :src="category.banner_path ?? defaultImage" alt="banner" class="category-image"/>
                    <h3 class="category-name">{{ category.name }}</h3>
                    <div class="subcategories">
                        <a :href="'/categories/' + item.slug + '/products'" class="subcategory" v-for="item in category.categories_child"
                              :key="item.id">{{ item.name }}</a>
                    </div>
                </a>
            </VueSlickCarousel>
        </section>
        <a :href="categories.url"  class="view-more-button" v-if="categories.button != null && categories.url != null">{{ categories.button}}</a>
    </section>
</template>
