<script>
import VueSlickCarousel from 'vue-slick-carousel';
export default {
    name: "about",
    components: {
        VueSlickCarousel,
    },
    props: {
        data: String,
        defaultImage: {
            type: String,
            default: "build/assets/banner_about.png"
        },
        imageShowcase: {
            type: String,
            default: "build/assets/banner_about.png"
        }
    },
    data() {
        return {
            sliderSettings: {
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                arrows: false,
                dotsClass: "slick-dots custom-dots",
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        },
                    },
                ],
            },
            sliders: []
        };
    },
    beforeMount() {
        this.sliders = JSON.parse(this.data);
    }
};
</script>
<template>
    <div class="about-showcase">
        <div class="about-grid-showcase">
            <div class="about-column-showcase">
                <VueSlickCarousel v-bind="sliderSettings">
                    <div class="about-image-container" v-for="item in sliders" :key="item.id">
                        <img :src="item.path ?? defaultImage" alt="banner" class="about-image-showcase"
                            loading="lazy" />
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>
